@import "theme";

html, body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  min-height: 100%;
  padding: 0;
  margin: 0;

  font-family: $primaryFont;

  background-color: $lightBackgroundColor;

  @media (prefers-color-scheme: dark) {
    background-color: $darkBackgroundColor;
  }

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: 30px;
  }
}

a, span.link {
  text-decoration: none;
  font-weight: 500;

  cursor: pointer;
  color: dodgerblue;

  &:hover {
    color: cornflowerblue;
  }
}

.wrapper-container {
  visibility: hidden;
  opacity: 0;

  $transitionDuration: 0.5s;
  $transition: $transitionDuration ease-in-out;
  transition: opacity $transition, visibility $transition;

  &.visible {
    visibility: visible;
    opacity: 1;

    $transition: $transitionDuration ease;
    transition: opacity $transition, visibility $transition;
  }
}