.panel-wrapper {
  width: 100%;
  display: flex;
  flex-flow: column;

  .panel-header {
    padding: 0 10px;

    h1 {
      font-size: 1.2em;
      font-weight: 700;
    }
  }

  .panel-scrollable {
    flex: 1;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;

    display: flex;
    flex-flow: column;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background-color: rgba(black, 0.2);
    }

    &::-webkit-scrollbar-thumb {
      background-color: darkgrey;
      border-radius: 15px;
    }

    .scrollable-item {
      width: calc(100% - 5px);
      flex-shrink: 0;

      &:hover {
        background-color: rgba(black, 0.1);
      }
    }
  }
}