#scoreboard-wrapper {
  .panel-scrollable {
    .scoreboard-user {
      display: flex;
      align-items: center;

      .user-icon {
        width: 40px;
        height: 40px;
        border-radius: 10px;
        margin: 5px;
      }

      .user-info {
        flex: 2;

        .user-name {
          font-weight: 700;
        }

        .user-state {
          font-weight: 300;
        }
      }

      .user-score {
        margin-right: 5px;

        font-size: 1.5em;
        font-weight: 500;
      }
    }
  }
}