@import "../../theme";
$headerMargin: 10px;

#header-wrapper {
  margin: 0 $headerMargin;

  width: 100%;
  height: 45px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  h1 {
    margin: 0;

    font-size: 1.2em;
    font-weight: 700;
  }

  .header-item {
    display: flex;
    align-items: center;

    &#header-title {
      .header-logo {
        height: 30px;
        margin-right: 10px;
      }
    }

    &#menu-button {
      border-radius: 5px;
      padding: 5px 7px;
      cursor: pointer;

      transition: background-color 0.1s ease;
      -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);

      &:hover {
        background-color: rgba(black, 0.1);
      }

      &.with-menu {
        background-color: rgba(black, 0.2);
      }

      .menu-icon {
        display: flex;
        align-items: center;
        justify-content: center;

        margin-left: 8px;
        font-size: 1.2em;
      }
    }
  }
}

#header-menu-container {
  position: absolute;
  top: 0;
  right: 0;

  margin: 5px 15px $headerMargin $headerMargin;
  padding: 5px;

  min-width: 225px;
  z-index: 30;

  visibility: hidden;
  opacity: 0;
  transition: 0.1s ease;

  background-color: #242424;
  border-radius: 5px;

  box-shadow: 3px 3px 10px rgba(black, 0.6);

  &.visible {
    visibility: visible;
    opacity: 1;
    margin-right: $headerMargin;
  }

  #header-menu {
    display: flex;
    flex-flow: column;

    color: $lightTextColor;

    .menu-item {
      box-sizing: border-box;
      padding: 5px 5px;
      width: 100%;

      text-align: right;
      border-radius: 5px;

      cursor: pointer;

      display: flex;
      justify-content: flex-end;
      align-items: center;

      &:hover {
        background-color: rgba(white, 0.1);
      }

      .title {
        margin-right: 3px;
      }

      .icon {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 25px;
        height: 25px;

        font-size: 1.1em;
      }
    }
  }
}