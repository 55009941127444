$lightBackgroundColor: #f0f0f0;
$darkBackgroundColor: #4f4f4f;

$lightSidebarColor: white;
$darkSidebarColor: #3d3d3d;

$lightTextColor: white;
$darkTextColor: black;

$lightAltTextColor: rgba(white, 0.6);
$darkAltTextColor: rgba(black, 0.6);

$lightButtonColor: #dedede;
$darkButtonColor: #595959;

$primaryFont: 'Roboto Slab', serif;
$cursiveFont: 'Indie Flower', cursive;

$errorColor: #ff4747;

$sidebarWidth: 280px;
$hideSidebarsAt: 1000px;
$shrinkPaddingAt: 330px;