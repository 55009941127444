@import "./setup";

#signup-form {
  #name-hint .username-error {
    margin-top: 2px;
    color: $errorColor;
    font-weight: 400;
    font-size: 0.8em;
  }

  #icon-selection {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    flex-shrink: 0;

    .icon-wrapper {
      &:hover, &.selected {
        margin: 2px;
        width: 58px;
        height: 58px;
        border-width: 4px;

        .icon {
          font-size: 27px;
        }
      }

      &:hover {
        border-color: grey;
      }

      &.selected {
        border-color: dodgerblue;

        @media (prefers-color-scheme: dark) {
          border-color: gold;
        }
      }
    }

    @media (min-width: 494px) {
      :nth-child(n+15) {
        display: none;
      }
    }

    @media (max-width: 533px) and (min-width: 493px) {
      :nth-child(n+13) {
        display: none;
      }
    }

    @media (max-width:493px) and (min-width: 492px) {
      :nth-child(n+13) {
        display: none;
      }
    }

    @media (min-width: 430px) {
      :nth-child(n+16) {
        display: none;
      }
    }

    @media (max-width: 348px) {
      :nth-child(n+13) {
        display: none;
      }
    }
  }

  @media (max-width: 553px) and (min-width: 494px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  @media (max-width: 429px) and (min-width: 368px){
    padding-left: 30px;
    padding-right: 30px;
  }

  @media (max-width: 369px) {
    padding-left: 20px;
    padding-right: 20px;
  }
}