@import "../theme";

.button {
  background-color: $lightSidebarColor;
  transition: background-color 0.1s ease;

  @media (prefers-color-scheme: dark) {
    background-color: $darkButtonColor;
  }

  margin: 30px auto;
  padding: 10px 40px;

  border: 2px solid black;
  border-radius: 10px;

  cursor: pointer;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);

  font-weight: 700;

  display: flex;
  justify-content: center;
  align-items: center;

  &.small {
    width: 145px;
  }

  &:hover {
    background-color: rgba(black, 0.05);
    @media (prefers-color-scheme: dark) {
      background-color: rgba(white, 0.1);
    }
  }

  &.disabled {
    cursor: not-allowed;

    background-color: rgba(black, 0.25);
    color: rgba(black, 0.4);

    @media (prefers-color-scheme: dark) {
      background-color: rgba(red, 0.1);
      color: rgba(white, 0.35);
    }
  }
}

.action-btn {
  $iconSize: 30px;

  .action-btn-icon-container {
    box-sizing: border-box;

    color: rgba(black, 0.7);

    @media (prefers-color-scheme: dark) {
      color: rgba(white, 0.7);
    }

    width: $iconSize;
    height: $iconSize;

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;

    &:hover {
      background-color: rgba(black, 0.08);

      @media (prefers-color-scheme: dark) {
        background-color: rgba(white, 0.1);
      }
    }
  }

  .action-btn-title-container {
    box-sizing: border-box;

    position: absolute;
    top: -8px;
    transform: translateY(-100%) translateX(calc(-50% + #{$iconSize / 2}));

    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;

    box-shadow: 0 3px 10px rgba(black, 0.3);
    border-radius: 5px;

    visibility: hidden;
    opacity: 0;

    transition: visibility 0.1s ease, opacity 0.1s ease;

    &:before, &:after {
      content: "";
      position: absolute;
      bottom: -5px;
      z-index: 12;
      width: 0;
      height: 0;
      border: solid 5px transparent;
      border-bottom: 0;
      border-top-color: black;
    }

    .action-btn-title {
      color: $lightTextColor;
      text-align: center;
      padding: 5px 6px;
      font-size: 0.9em;
      font-weight: 500;
    }
  }

  &.hovered {
    .action-btn-title-container {
      visibility: visible;
      opacity: 1;
    }
  }

  &.disabled {
    .action-btn-icon-container {
      cursor: default;
    }
  }
}