@import "../splash";
@import "./setup";

#room-setup {
  .buttons-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 15px 0;

    .setup-button {
      margin: 15px;
    }

    &.long-list {
      .setup-button {
        margin: 10px;
        width: 90px;
        padding: 8px 20px;
      }
    }

    @media (max-width: $initialWidth) {
      .setup-button {
        margin: 10px;
        width: 110px;
        padding: 10px 40px;
      }
    }
  }

  p {
    margin: 20px 0 15px;
  }

  .game-tutorial {
    text-align: left;

    h3 {
      margin: 10px 0;
    }

    p {
      margin: 5px 0;

      font-size: 0.9em;
      font-weight: 300;
    }
  }

  #room-input-container {
    box-sizing: border-box;
    width: 100%;

    padding: 0 20px;

    @media (max-width: 430px) {
      padding: 0;
    }
  }

  .room-option {
    display: flex;
    justify-content: center;
    align-items: center;

    box-sizing: border-box;
    padding: 5px 30px;
    width: 100%;

    @media (max-width: 430px) {
      padding: 5px;
    }

    .room-option-label {
      flex: 1;
      flex-shrink: 0;

      min-width: 105px;
      margin: 0;

      font-size: 0.9em;
      font-weight: 400;
      text-align: right;
    }

    .room-option-dropdown {
      flex: 2;
      margin-left: 20px;

      @media (max-width: 370px) {
        width: 100px;
      }

      .dropdown-container {
        text-align: left;
        font-size: 0.9em;

        color: rgba(black, 0.4);

        .dropdown__control {
          background-color: $lightSidebarColor;
          box-shadow: none;

          border-color: black;
          border-width: 2px;
          border-radius: 10px;

          @media (prefers-color-scheme: dark) {
            background-color: $darkButtonColor;
          }

          .dropdown__value-container .dropdown__single-value {
            color: $darkTextColor;

            @media (prefers-color-scheme: dark) {
              color: $lightTextColor;
            }
          }

          .dropdown__indicators {
            .dropdown__indicator-separator {
              background-color: transparent;
            }

            .dropdown__indicator {
              color: rgba(black, 0.3);

              &:hover {
                color: rgba(black, 0.5);
              }

              @media (prefers-color-scheme: dark) {
                color: rgba(white, 0.3);

                &:hover {
                  color: rgba(white, 0.5);
                }
              }
            }
          }

          &.dropdown__control--menu-is-open {

            .dropdown__indicators {
              .dropdown__indicator {
                color: black;

                @media (prefers-color-scheme: dark) {
                  color: white;
                }
              }
            }
          }
        }

        .dropdown__menu {
          border-radius: 10px;

          .dropdown__menu-list {
            border: 2px solid black;
            border-radius: 10px;
            background-color: $lightSidebarColor;
            padding: 0;

            @media (prefers-color-scheme: dark) {
              background-color: $darkButtonColor;
            }

            .dropdown__option {
              color: rgba(black, 0.6);

              @media (prefers-color-scheme: dark) {
                color: rgba(white, 0.6);
              }

              &.dropdown__option--is-focused {
                background-color: rgba(black, 0.15);
              }

              &.dropdown__option--is-selected {
                background-color: dodgerblue;
                color: white;
              }
            }
          }
        }
      }
    }

    &.with-error {
      .room-option-dropdown .dropdown-container .dropdown__control {
        border-color: $errorColor;
      }
    }
  }

  .setup-warning {
    margin-top: 20px;
    color: $errorColor;
    font-size: 0.9em;
    font-weight: 300;
  }

  .room-link-container {
    display: flex;
    align-items: center;
    color: dodgerblue;

    .room-link-icon-container {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 25px;
      height: 25px;

      cursor: pointer;

      font-size: 1em;

      &:hover {
        font-size: 1.1em;
      }

      &:active {
        font-size: 1.2em;
      }

      .room-link-icon {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .room-link-textarea {
      position: absolute;
      left: -1000px;
    }

    .room-link-text {
      display: inline-block;
      word-wrap: break-word;
    }
  }

  .rooms-list-container {
    .rooms-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      max-height: 175px;
      overflow-y: scroll;

      .room-card {
        box-sizing: border-box;
        width: 215px;

        @media (max-width: $initialWidth + 20px) {
          width: 90%;
        }

        display: flex;
        flex-flow: column;

        border: 2px solid black;
        border-radius: 5px;

        margin: 5px;
        padding: 5px;
        min-height: 160px;

        text-align: left;

        background-color: $lightSidebarColor;

        @media (prefers-color-scheme: dark) {
          background-color: rgba(white, 0.12);
        }

        .room-header {
          font-size: 1.3em;
        }

        .room-info {
          flex: 1;

          p {
            margin: 4px 0;

            font-size: 0.8em;
            font-weight: 300;
          }
        }

        .join-room-button {
          text-align: center;
          width: 50px;

          padding: 5px 10px;
          margin: 0;

          background-color: dodgerblue;

          &:hover {
            background-color: cornflowerblue;
          }
        }
      }
    }
  }
}