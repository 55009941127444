@import "../../theme";

$cardWidth: 260px;
$cardHeight: 204px;

.outer-card {
  width: $cardWidth;
  height: $cardHeight;
  perspective: 1000px;

  color: black;

  .inner-card {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.4s;
    transform-style: preserve-3d;

    &.flipped {
      transform: rotateY(180deg);
    }

    .card {
      box-sizing: border-box;
      position: absolute;
      width: 100%;
      height: 100%;
      padding: 10px 20px;

      backface-visibility: hidden;
      --webkit-backface-visibility: hidden;
      -webkit-transform: translate3d(0,0,0);

      border: 2.0px solid black;
      border-radius: 10px;

      &.front {
        background-color: white;

        .text {
          color: black;
          text-align: left;

          display: block;
          cursor: text;
          padding: 0;
          margin: 5px 0 0;
        }

        .card-controls-wrapper {
          position: absolute;
          right: 10px;
          bottom: 10px;

          .card-controls {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .card-btn {
              .action-btn-icon-container {
                font-size: 22px;
                background-color: transparent;

                color: $darkButtonColor;

                &:hover:not(.disabled) {
                  color: dodgerblue;
                }
              }

              &.active .action-btn-icon-container {
                color: dodgerblue;
              }

              .action-btn-title {
                padding: 8px 10px;
                font-size: 0.9em;
              }
            }

            .answer-guess-icon {
              .icon-wrapper {
                width: 29px;
                height: 29px;
                border-width: 1px;
                margin: 0 2px;

                .icon {
                  font-size: 1.1em;
                }
              }
            }
          }
        }

        &.response .text {
          font-family: $cursiveFont;
          font-size: 1.1em;
        }

        &.input {
          .text {
            box-sizing: border-box;
            width: 100%;
            height: calc(100% - 50px);

            resize: none;
            outline: none;
            border: none;
          }

          .corner {
            position: absolute;
            bottom: 8px;
            right: 10px;
            height: 30px;

            display: flex;
            align-items: center;

            .submit-text {
              margin: 0;
              padding-right: 2px;
              font-size: 0.9em;
            }

            .submit-btn-wrapper {
              width: 30px;
              height: 30px;

              display: flex;
              justify-content: center;
              align-items: center;

              .submit-icon {
                cursor: pointer;

                &:hover {
                  color: dodgerblue;
                }
              }
            }
          }

          &.error {
            padding: 9px 19px;
            border: 3px solid red;

            .text::placeholder {
              color: #fc7777;
            }

            .corner {
              right: 9px;
            }
          }
        }
      }

      &.back {
        transform: rotateY(180deg);

        background-color: $darkSidebarColor;

        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;

        .card-logo {
          width: 110px;
          height: 110px;
        }

        .card-title {
          color: rgba(white, 0.9);
          font-size: 1.45em;
          font-weight: 500;
        }
      }
    }
  }

  &.can-hover:hover {
    cursor: pointer;

    .inner-card .card {
      padding: 8px 18px;
      border: 4px solid dodgerblue;

      &.front {

        .text {
          cursor: pointer;
        }

        .card-controls-wrapper {
          right: 8px;
          bottom: 8px;
        }
      }
    }
  }

  &.guess-correct .inner-card .card {
    background-color: #8cff8a;

    .card-controls-wrapper .card-controls .answer-guess-icon .icon-wrapper {
      border: 2px solid green;
    }
  }

  &.guess-incorrect .inner-card .card  {
    background-color: #ffa18a;

    .card-controls-wrapper .card-controls .answer-guess-icon .icon-wrapper {
      border: 2px solid red;
    }
  }
}