@import "../../theme";

#chat-wrapper {
  .text-input {
    border: none;
    outline: none;
    resize: none;

    overflow: hidden;
    font-size: 1em;
    border-radius: 5px;

    background-color: rgba(black, 0.15);
    color: $darkTextColor;

    font-family: $primaryFont;

    @media (prefers-color-scheme: dark) {
      background-color: rgba(white, 0.07);
      color: $lightTextColor;
    }
  }

  #chat-input-container {
    margin: 10px;

    #chat-input {
      box-sizing: border-box;
      width: 100%;
      padding: 5px;
    }
  }
}