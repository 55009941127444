@import "../theme";

$initialWidth: 570px;

.setup-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background-color: $lightSidebarColor;

  @media (prefers-color-scheme: dark) {
    background-color: $darkSidebarColor;
  }

  display: flex;
  justify-content: center;
  align-items: center;

  .outer-setup-container {
    box-sizing: border-box;
    width: $initialWidth + 20px;
    max-height: calc(100% - 4px);

    @media (max-width: $initialWidth) {
      width: 100%;

      border-radius: 0;
      border-left: none;
      border-right: none;
    }

    border: 2px solid black;
    border-radius: 10px;

    background-color: $lightBackgroundColor;

    @media (prefers-color-scheme: dark) {
      background-color: $darkBackgroundColor;
      color: $lightTextColor;
    }

    display: flex;
    flex-direction: column;
    padding: 20px 30px;

    .inner-setup-container {
      box-sizing: border-box;
      padding: 20px 30px 10px;

      width: 100%;
      height: 100%;

      overflow-y: auto;

      display: flex;
      flex-flow: column;
      align-items: center;

      text-align: center;

      h1 {
        margin: 0;
      }

      h2 {
        font-size: 0.8em;
        font-weight: 400;
        margin: 3px;
      }

      p {
        font-size: 1.1em;
        font-weight: 500;
      }

      .setup-input {
        box-sizing: border-box;

        background-color: $lightSidebarColor;
        width: 95%;
        height: 40px;

        border: 2px solid black;
        border-radius: 10px;

        &.with-error {
          border-color: $errorColor;
        }

        font-family: 'Indie Flower', cursive;

        color: black;
        outline: none;

        padding: 5px 10px;
        font-size: 1.1em;

        @media (prefers-color-scheme: dark) {
          background-color: $darkButtonColor;
          color: white;

          &::placeholder {
            color: rgba(white, 0.55);
          }
        }
      }
    }
  }
}