@import "theme";


.splash-wrapper {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;

  background-color: $lightBackgroundColor;
  color: $darkTextColor;

  @media (prefers-color-scheme: dark) {
    background-color: $darkBackgroundColor;
    color: $lightTextColor;
  }

  .splash-logo {
    width: 200px;
    height: 200px;
  }

  h1 {
    margin: 0;
    font-size: 2.9em;
  }

  h2 {
    font-size: 1.1em;
    font-weight: 500;
    margin: 2px;

    color: $darkAltTextColor;

    @media (prefers-color-scheme: dark) {
      color: $lightAltTextColor;
    }
  }
}

.loading-icon-container {
  .loading-icon {
    font-size: 3.2em;

    .fa-primary {
      color: dodgerblue;
    }

    .fa-secondary {
      color: $darkAltTextColor;

      @media (prefers-color-scheme: dark) {
        color: $lightAltTextColor;
      }
    }
  }
}