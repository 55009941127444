@import "../../theme";

#outer-game-wrapper {
  width: 100%;
  height: 100%;

  overflow-y: auto;

  background-color: $lightBackgroundColor;

  @media (prefers-color-scheme: dark) {
    background-color: $darkBackgroundColor;
  }

  #inner-game-wrapper {
    box-sizing: border-box;

    width: 100%;
    height: 100%;

    display: flex;
    flex-flow: column;
    align-items: center;

    &::before, &::after {
      content: "";
      flex: 1;
    }

    text-align: center;
    padding: 25px;

    @media (max-width: $shrinkPaddingAt + 10px) {
      padding-left: 15px;
      padding-right: 15px;
    }

    h1 {
      margin: 10px 0;
      font-size: 1.7em;
    }

    p {
      margin: 5px 0;
    }

    .card-list {
      box-sizing: border-box;

      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      .outer-card {
        margin: 10px;
      }
    }
  }
}

#read-answers-btn {
  margin: 20px 10px;
}