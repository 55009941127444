.icon-wrapper {
  box-sizing: border-box;
  margin: 4px;

  border: 2px solid black;
  border-radius: 20px;

  width: 54px;
  height: 54px;

  display: flex;
  justify-content: center;
  align-items: center;

  flex-shrink: 0;

  .icon {
    font-size: 25px;

    display: flex;
    justify-content: center;
    align-items: center;
  }
}